import { render, staticRenderFns } from "./telephonePermissions.vue?vue&type=template&id=73bb7fda&scoped=true"
import script from "./telephonePermissions.vue?vue&type=script&lang=js"
export * from "./telephonePermissions.vue?vue&type=script&lang=js"
import style0 from "./index.less?vue&type=style&index=0&id=73bb7fda&prod&lang=less&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73bb7fda",
  null
  
)

export default component.exports