<template>
  <div id="privacy-box" class="shop-window-index isMobile" style="min-height: 100vh;background: #fff">
      <div class="content" style="margin-top:0.3em;">
        <p style="text-indent: 0em;"><b>使用须知：</b></p>
        <p style="margin-top: .5em;">1、我们访问您的电话是为了获得您的设备信息、必要的身份验证，是为提供服务所必须收集的基础信息，以标识您为海信爱家的用户。</p>
        <p style="margin-top: .5em;">2、我们会采用符合业界标准的安全防护措施来保护您的个人信息安全，避免遭到未经授权的访问、公开披露、使用、修改、损坏或丢失。我们努力使用各种合理的制度、技术、程序以及物理层面的措施来保护您的个人信息，包括但不限于防火墙、加密（例如SSL）、去标识化或匿名化处理、访问控制措施等。</p>
        <p style="margin-top: .5em;">3、您可以通过“个人中心—设置—隐私设置”逐项查看您上述个人信息的访问权限开启状态，并可以决定开启或关闭这些权限（我们会指引您在您的设备系统中完成设置）。开启这些权限即代表您授权我们可以收集和使用这些个人信息来实现上述的功能，关闭这些权限即代表您取消了这些授权，则我们将不再继续收集和使用您的这些个人信息，也无法为您提供上述与这些授权所对应的功能。您关闭权限的决定不会影响此前基于您的授权所进行的个人信息的处理。</p>
        <p style="margin-top: .5em;">4、您可以查看<span style="display: inline; color: #00AAA6;cursor: pointer;" @click="clickPushToPrivacy">《海信信天翁系统隐私政策》</span>以获得更详细的隐私政策信息。</p>
      </div>
  
    </div>
  </template>
  <script>
    export default {
        methods: {
            clickPushToPrivacy(){
                this.$router.push("/privacy");
            }
        }
    }
  </script>
  <style lang="less" src="./index.less" scoped></style>
  <!-- <script lang="javascript" src="./index.js"></script> -->
  